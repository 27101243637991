<template>
    <!--begin::Dashboard-->
    <div>
        <div class="card card-custom gutter-b col-lg-13 col-xxl-12 mt-5 p-5">
            <div class="d-flex align-items-center justify-content-between">
                <div style="width:10%">
                    <router-link :to="{ name: 'admin/dashboard', query: { activeTable: 'clients' } }" replace>
                        <span class="btn btn-outline-danger btn-md">
                            <span class="svg-icon svg-icon-md svg-icon-light-danger">
                                <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                            </span>
                            Retour
                        </span>
                    </router-link>
                </div>
                <div class="text-center">
                    <p class="h3 text-primary">Affectation de classe pour le client : {{ this.$route.params.client.name }}</p>
                    <span class="text-muted font-italic">code : {{ this.$route.params.client.code }}</span>
                </div>
                <div style="width:10%"></div>
            </div>
        </div>
        <div class="card card-custom gutter-b col-lg-13 col-xxl-12 mt-5 p-5">
            <div
                class="m-0 d-flex flex-wrap align-items-center mt-5"
                :class="mobile ? 'justify-content-center' : 'justify-content-between'"
            >
                <div
                    class="d-flex flex-wrap align-items-center mb-2"
                    :class="mobile ? 'justify-content-center' : 'justify-content-between'"
                    style="width:100%"
                >
                    <b-form-group :style="mobile ? 'width:100%' : 'width:60%'" class="m-0" label-for="filterInput">
                        <b-input-group>
                            <b-form-input v-model.lazy="filter" type="search" id="filterInput" placeholder="Rechercher" />
                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">Effacer</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </div>
                <b-table
                    :items="classes"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    @filtered="onFiltered"
                    :current-page="currentPage"
                    :per-page="perPage"
                    responsive
                    hover
                    class="mx-0 p-0"
                    :class="mobile ? 'mb-5 mt-0' : 'my-5'"
                >
                    <template #cell(title)="row">
                        <div style="max-width:30rem;max-height: 5vh" class="text-truncate" v-b-tooltip.hover.left="`${row.item.title}`">
                            {{ row.item.title }}
                        </div>
                    </template>
                    <template #cell(description)="row">
                        <div
                            style="max-width:30rem;max-height: 5vh"
                            class="text-truncate"
                            v-b-tooltip.hover.left="`${row.item.description}`"
                        >
                            {{ row.item.description }}
                        </div>
                    </template>
                    <template #cell(goal)="row">
                        {{ row.item.goal === null ? 'Inderterminé' : row.item.goal + '%' }}
                    </template>
                    <template #cell(Status)="row">
                        <div v-if="haveClasse(row.item.id)">
                            <span :class="haveClasse(row.item.id)[1] < 50 ? 'text-warning' : 'text-primary'">
                                {{ haveClasse(row.item.id)[1] }}</span
                            >
                        </div>
                        <div v-else>
                            <span class="text-danger"> Non affectée </span>
                        </div>
                    </template>
                    <template #cell(date_limit)="row">
                        <div v-if="haveClasse(row.item.id)">
                            <div v-for="clientClasse in clientClasses.data" :key="clientClasse">
                                <span v-if="clientClasse.classe.id === row.item.id"> {{ formatDate(clientClasse.date_limit) }} </span>
                            </div>
                        </div>
                    </template>
                    <template #cell(created_at)="row">
                        {{ formatDate(row.item.created_at) }}
                    </template>
                    <template #cell(updated_at)="row">
                        {{ formatDate(row.item.updated_at) }}
                    </template>
                    <template #cell(Action)="row">
                        <div class="d-flex flex-row align-items-center w-25">
                            <button
                                class="btn btn-clean btn-sm svg-icon svg-icon-primary"
                                @click="select_classe_id = row.item.id"
                                v-b-modal.assign-param-modal
                                v-b-tooltip.hover.left="`${!haveClasse(row.item.id) ? 'Affecter la classe' : 'Ajouter des licences'}`"
                            >
                                <inline-svg v-if="haveClasse(row.item.id)" src="/media/svg/icons/Files/File-plus.svg" />
                                <inline-svg v-else src="/media/svg/icons/Communication/Incoming-box.svg" />
                            </button>
                            <button
                                v-if="haveClasse(row.item.id)"
                                class="btn btn-clean btn-sm svg-icon svg-icon-primary"
                                v-b-tooltip.hover.left="'Générer paquet SCORM'"
                                @click="generateScorm(row.item)"
                            >
                                <inline-svg src="/media/svg/icons/Files/Cloud-download.svg" />
                            </button>
                        </div>
                    </template>
                </b-table>
                <div
                    class="d-flex align-items-end"
                    :class="mobile ? 'justify-content-center' : 'justify-content-between'"
                    style="width:100%"
                >
                    <b-col sm="5" md="4">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"> </b-pagination>
                    </b-col>
                    <div>
                        <ul class="pagination" :class="mobile ? 'mt-2' : ''">
                            <li class="page-item active">
                                <a class="page-link bg-primary">Nombre de résultats : {{ this.classes ? this.classes.length : '' }}</a>
                            </li>
                        </ul>
                    </div>
                    <b-form-group
                        hidden
                        label="Nombre de résultats par page"
                        label-for="perPageSelect"
                        class="m-0 mr-2"
                        :class="mobile ? 'text-center' : ''"
                    >
                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions" />
                    </b-form-group>
                </div>
            </div>
            <b-modal id="assign-param-modal" header-text-variant="danger" title="Paramètre d'affection de la classe">
                <b-form-group label="Nombre de licences" label-for="input-license" class="mb-0">
                    <b-form-input
                        id="input-license"
                        v-model="input_license"
                        type="number"
                        v-on:keypress="NumbersOnly"
                        :placeholder="
                            'Entrer le nombre de licences que vous voulez ' + `${!haveClasse(select_classe_id) ? 'attribuer' : 'ajouter'}`
                        "
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Date d'expiration *" label-for="input-lifetime" class="mb-0">
                    <b-form-input id="input-lifetime" v-model="input_date_limit" type="date"></b-form-input>
                    <p>* Correspond à la date limite d'utilisation d'un paquet SCORM</p>
                </b-form-group>
                <template #modal-footer="{cancel}">
                    <b-button variant="primary" @click="assign()"> Affecter </b-button>
                    <b-button variant="secondary" @click="cancel">
                        {{ $t('CONTROLLERS.CANCEL') }}
                    </b-button>
                </template>
            </b-modal>
        </div>
    </div>
    <!--end::Dashboard-->
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { mapGetters } from 'vuex';

import { LIST_ONLINE_CLASSES } from '@/core/services/store/api/classe.service';
import { LIST_CLIENT_CLASSES } from '@/core/services/store/api/client.service';
import ApiService from '@/core/services/api.service';
//import i18n from '@/core/plugins/vue-i18n.js';
import moment from 'moment';
//import Nl2br from 'vue-nl2br';

export default {
    name: 'admin-assign-client',
    components: {
        //Nl2br
    },
    data() {
        return {
            input_license: null,
            input_date_limit: null,
            select_classe_id: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, { value: 100, text: 'Le plus possible' }],
            filter: null,
            isBusy: false,
            sortBy: 'id',
            sortDesc: false,
            fields: [
                { key: 'title', label: 'Nom', sortable: true, thStyle: 'width : 25%' },
                { key: 'description', label: 'Description', sortable: true, thStyle: 'width : 25%' },
                { key: 'goal', label: 'Objectif', sortable: false },
                { key: 'version', label: 'Version', sortable: false },
                { key: 'Status', label: 'Licence restante', sortable: true },
                { key: 'date_limit', label: 'Date limite', sortable: true },
                { key: 'Action', label: 'Actions', sortable: false }
            ]
        };
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Affecter une classe' }]);
        if (this.$route.params.client) {
            this.$store.dispatch(LIST_ONLINE_CLASSES);
            this.totalRows = this.classes.length;
            this.$store.dispatch(LIST_CLIENT_CLASSES, {
                customer_id: this.$route.params.client.id
            });
            //this.$store.dispatch(LIST_CLASSE, this.$route.params.id);
        } else {
            this.$router.push({ name: 'admin/dashboard' });
        }
    },
    computed: {
        ...mapGetters({ classes: 'getOnlineClasses', clientClasses: 'getClientClasses' }),
        mobile() {
            return window.screen.availWidth > 992 ? false : true;
        }
    },
    updated() {
        if (this.filteredItems) {
            if (this.filteredItems.length === 0) {
                this.totalRows = this.classes.length;
            }
        }
    },
    methods: {
        NumbersOnly (evt) {
            let keyCode = (evt.keyCode ? evt.keyCode : evt.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 45) {
                evt.preventDefault();
            }
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
        },
        makeToast(variant = null, text, title) {
            this.$bvToast.toast(text, {
                title: title,
                variant: variant,
                solid: true
            });
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        haveClasse(row_classe_id) {
            if (this.clientClasses) {
                let find = false;
                let id = null;
                let left = null;
                this.clientClasses &&
                    this.clientClasses.data.forEach(clientClasse => {
                        if (clientClasse.classe && clientClasse.classe.id == row_classe_id) {
                            find = true;
                            id = clientClasse.id;
                            left = clientClasse.left;
                        } else {
                            return false;
                        }
                    });
                if (find && id) {
                    return [id, left];
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        assign() {
            const clientclass_id = this.haveClasse(this.select_classe_id) ? this.haveClasse(this.select_classe_id)[0] : false;
            ApiService.post(process.env.VUE_APP_API_URL + '/academy/clientclasses' + `${clientclass_id ? '/' + clientclass_id : ''}`, {
                id: `${clientclass_id ? clientclass_id : null}`,
                classe_id: this.select_classe_id,
                customer_id: this.$route.params.client.id,
                license: this.input_license,
                date_limit: this.input_date_limit
            }).then(() => {
                this.select_classe_id = null;
                this.input_license = null;
                this.$store.dispatch(LIST_CLIENT_CLASSES, {
                    customer_id: this.$route.params.client.id
                });
                this.$bvModal.hide('assign-param-modal');
            });
        },
        generateScorm(classe) {
            ApiService.post(process.env.VUE_APP_API_URL + '/academy/scorm', {
                classe_id: classe.id,
                customer_id: this.$route.params.client.id
            })
                .then((result) => {
                    this.makeToast('success', 'Le package SCORM a bien été généré !', 'Réussite !');
                    window.open(result.data);
                })
                .error(() => {
                    this.makeToast('danger', 'Une erreur est survenu.', 'Erreur');
                });
        }
    }
};
</script>
<style>
td {
    vertical-align: middle !important;
}
</style>
